<template>
  <v-container fluid fill-height class="pa-0">
    <v-row justify="center" align="center" class="ma-0">
      <v-card class="pa-12 pt-0 ma-3 crad_box" style="width: 390px;">
        <v-row justify="center">
          <v-col align="center" class="my-10">
            <h1 style="color :#a9001d; font-family: 'Times New Roman', Times, serif;">CUSTOMER LOGIN</h1>
          </v-col>
        </v-row>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            autofocus
            @keyup.enter="$refs.refpassword.focus()"
            :value="username"
            @change="value => username = value"
            :rules="[ v => !!v || 'กรอก Username' ]"
            required
            ref="refusername"
            label="Username"
            outlined
            prepend-inner-icon="mdi-account-box"
            class="text-field-border-radius-50vh"
          ></v-text-field>
          <v-text-field
            @keyup.enter="bt_login_click()"
            :value="password"
            @change="value => password = value"
            :rules="[ v => !!v || 'กรอก Password' ]"
            required
            ref="refpassword"
            label="Password"
            outlined
            prepend-inner-icon="mdi-lock"
            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show_password ? 'text' : 'password'"
            @click:append="show_password = !show_password"
            class="text-field-border-radius-50vh"
          ></v-text-field>
          <v-col cols="12" class="pa-0" align="right">
              <v-btn
                class="btn-box"
                block
                color="info"
                :loading="bt_loading"
                :disabled="!valid || bt_loading"
                @click="bt_login_click()"
              >เข้าสู่ระบบ</v-btn>
          </v-col>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { header_token } from '@/website/token'
  import { branchOverviewService } from '@/website/global'
  import { AlertWarning } from '@/website/global_alert'
  export default {
    data: () => ({
      valid: true,
      bt_loading: false,
      username: '',
      password: '',
      show_password: false,
    }),
    created() {
      if(localStorage.getItem('customerID')!=null){
        this.$router.push('/client-check-status-best')
      }
    },
    methods: {
      async bt_login_click() {
        if (this.$refs.form.validate()) {
          this.snackbar = true
          this.bt_loading = true
          let response = await axios.post(branchOverviewService+'order/cust_login', {
              'Username': this.username,
              'Password': this.password
          })
          if (response.data.status == 'Success') {
            if(response.data.result.length>0){
              localStorage.setItem('customerID', response.data.result[0]['CustomerID'])
              this.$router.push('/client-check-status-best')
            }else{
              this.AlertWarning("ไม่พบข้อมูล")
              this.bt_loading = false
            }
          }else{
            this.AlertWarning("Username Password ไม่ถูกต้อง")
            this.bt_loading = false
          }
        }
      },
      AlertWarning,
    }
  }
</script>
